<template>
    <div class="object">
        <div class="objectBody">
            <div v-if="images.length > 0" class="objectImg"><MyProfile :images='images'/></div>
            <div class="objectInfos">
                <div><h4>Description</h4><span>{{ infos.description }}</span></div>
                <div><h4>Prix</h4>
                    <span>{{ infos.price }} FCFA</span></div>
                <div><h4>Type</h4><span>{{ infos.type }}</span></div>
                <span @click="startChat(iWanBuy)" > ACHETER CET OBJET </span>
            </div>
            <div class="objectPublisher">
                <h4>Publié par</h4>
                <span>{{ infos.creator }} le {{ setDate(infos.creationDate) }}</span>
                <div @click="startChat('')">
                    Le contacter</div>
            </div>
        </div>
        <div class="objectFooter"><JFooter/></div>

        <Contact ref="contact" :infos="{name:infos.creator, type:'Me contacter à',contact:infos.tel}"/>
        <JNavbar
            @inscription='$refs.inscription.Display=true'
            @connexion='$refs.connexion.Display=true'
            @messages='$refs.chatBox.Display=true'
        />
        <Inscription ref='inscription' @connexion='$refs.connexion.Display=true'/>
        <Connexion ref='connexion' @inscription='$refs.inscription.Display=true'/>
        
        <ChatBox ref="chatBox"/>
        <LLoading1 :visible='loading1'/>
    </div>
</template>
<style src="./object.css">
</style>
<script src = "./object.js">
</script>
