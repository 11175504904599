import ImgUpload from '@/components/global/imgUpload/ImgUpload'
import InputSearch from '@/components/global/inputSearch/InputSearch'
import AlertG from '@/components/global/alertG/AlertG'
import LLoading1 from '@/components/global/lLoading1/LLoading1'

export default {
  name: 'lLoading',
  props: {
    type: {
      default:'lost',
      type:String
    },
    edit: {
      default:'not',
      type:String
    }
  },
  emits:["connexion"],
  components: {
    ImgUpload,
    InputSearch,
    AlertG,
    LLoading1
  },
  computed: {
    Position () {
      return this.position + '%'
    }
  },
  data () {
    return {
      loading:false,
      Display:false,
      position:0,
      transition:'left 0.7s ease-out',
      infos:{id:-1},
      toSend0:{},
      toSend1:{},
      toSend2:{},
      slides: [
        {
          title:"Quel est cet object ?",
          subtitle:"Veillez remplir les informations sur l'objet.",
          selects:[
            {name:"type", value:"Téléphone",options:this.$store.state.types}
          ],
          city:false,
          textareas: [
            {name:"description", value:"",placeholder:"Description"}
          ],
          images:[]
        },
        {
          title:"Ville et prix de l'objet",
          subtitle:"Veillez remplir les informations pour aider",
          selects:[
          ],
          city:true,
          inputs: [
            {name:"price",type:"number", value:"",placeholder:"Prix"}
          ],
          textareas: [
          ],
          images:[]
        },
        {
          title:"Quelques images de l'objets",
          subtitle:"Ajouter des images de l'objet (obligatioire)",
          city:false,
          selects:[
          ],
          inputs: [
          ],
          textareas: [
          ],
          images:[{name:'lost'},{name:'lost'},{name:'lost'},{name:'lost'}]
        }
      ]
    }
  },
  methods: {
    touching (event) {
      let elt = document.getElementById('IstepsFormP'+this.type+this.edit)
      //alert(typeof elt.contains(event.target))
      this.$refs["stepsFormI"][0].dontClear = false
      if(!elt.contains(event.target)) {
        //alert('ff')
        this.$refs["stepsFormI"][0].blurred()
      }
    },
    move (i) {
      if ((this.position >= -200 && i === -1) || (this.position < 0 && i === 1)) {
        this.position += i * 100
      }
    },
    loadObject (elt) {
      this.infos = elt
      let slides = this.slides
      slides[0].selects[0].value = this.infos.type
      slides[0].textareas[0].value = this.infos.description
      this.$refs.stepsFormI[0].setDefault(this.infos.city)
      slides[1].inputs[0].value = this.infos.price
      let bsrl = this.$store.state.baseUrl + 'images/'
      this.$refs.images[0].url = this.infos.img0 == '' ? '' : (this.infos.img0.includes('://') ?'':bsrl) + this.infos.img0
      this.$refs.images[1].url = this.infos.img1 == '' ? '' : (this.infos.img1.includes('://') ?'':bsrl) + this.infos.img1
      this.$refs.images[2].url = this.infos.img2 == '' ? '' : (this.infos.img2.includes('://') ?'':bsrl) + this.infos.img2
      this.$refs.images[3].url = this.infos.img3 == '' ? '' : (this.infos.img3.includes('://') ?'':bsrl) + this.infos.img3
      this.Display = true
    },
    updateObject () {
      let slides = this.slides
      this.infos.type = slides[0].selects[0].value
      this.infos.description = slides[0].textareas[0].value
      this.infos.price = slides[1].inputs[0].value
      
      let bsrl = this.$store.state.baseUrl + 'images/'
      this.infos.img0 = this.$refs.images[0].url.replace(bsrl,'' )
      this.infos.img1 = this.$refs.images[1].url.replace(bsrl,'' )
      this.infos.img2 = this.$refs.images[2].url.replace(bsrl,'' )
      this.infos.img3 = this.$refs.images[3].url.replace(bsrl,'' )
    },
    close () {
      this.Display = false
      this.transition = 'none'
      this.position = 0
      this.transition = 'left 0.7s ease-out'
    },
    //------------ validation --------------------------------------
    validation () {
      let step = Math.abs(this.position/100)
      let slide = this.slides[step]

      if(step === 0) {
        if (slide.textareas[0].value.length < 4) {
          this.$refs.alertG.displaying('Description trop courte (4 charactères minimun).')
          return false
        }
        else if (slide.textareas[0].value.length >= 350) { 
          this.$refs.alertG.displaying('Description trop longue (350 charactère maximun).')
          return false
        }
        this.toSend0 = {type:slide.selects[0].value,description:slide.textareas[0].value}
        this.move(-1)
        return true
      }
      else if(step === 1) {
        let regex = /^[0-9]{0,11}$/
        if (this.$refs.stepsFormI[0].getCity() == -1) {
          this.$refs.alertG.displaying('Veuillez choisr une ville')
          return false
        }
        if (!regex.test(slide.inputs[0].value) || slide.inputs[0].value === '') {
          this.$refs.alertG.displaying(slide.inputs[0].placeholder+' incorrect')
          return false
        }
        this.toSend1 = {ville:this.$refs.stepsFormI[0].getCity(),price:slide.inputs[0].value}
        this.move(-1)
        return true
      }
      else if(step === 2) {
        //---- if not connected ----------
        if(!this.$store.state.user.connected) {
          this.$emit('connexion')
          return
        }
        
        const axios = require('axios')
        let i = 0
        let formData = new FormData()
        formData.append('aim', 'newObject')
        formData.append('userId', this.$store.state.user.id)
        formData.append('id', this.infos.id)
        if(this.infos.id != -1) {
          formData.append('imgName', this.infos.imgName)
          formData.append('img0', this.infos.img0)
          formData.append('img1', this.infos.img1)
          formData.append('img2', this.infos.img2)
          formData.append('img3', this.infos.img3)
        }
        //----- let's append objects properties -----------
        let toSend ={...this.toSend0,...this.toSend1}
        for(const index in toSend) {
          formData.append(index,toSend[index])
        }

        let k=0,images = this.$refs.images
        
        //---- return if no image ---------------------
        for(i=0;i<images.length;i++) {
          if(images[i].url =='') k++
        }
        if(k==4) {
          this.$refs.alertG.displaying('Choisir au moins une image')
          return
        }

        //---- let's append images -----------
        for(i=0;i<images.length;i++) {
          if(images[i].modified && images[i].url !='') {
            formData.append('image'+i, images[i].file)
          }
          // image to be deleted -------
          else if(images[i].modified && images[i].url == '') {
            formData.append('image'+i, 'toDelete')
          }
        }
        //--------- now sending the request ----------------------
        this.loading = true
        axios.post(this.$store.state.baseUrl + 'publication.php', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then(() => {
          this.loading = false
          if(this.infos.id != -1) this.updateObject()
          else {
            this.slides[0].textareas = []
            this.slides[2].images = []
            this.$emit('newElt')
          }
          this.$refs.alertGC.displaying('Succes!')
        })
        .catch((error) => {
          console.log(error)
          this.loading = false  
        })
      }
    }
  }
}
