<template>
  <router-view/>
  <image-preloader
  :srcs="[

  ]"
  @loaded-all="loadedAll"
  />
</template>

<script>
import ImagePreloader from './ImagePreloader.vue'

export default {
  name: 'App',
  components: {
    ImagePreloader
  },
  methods: {
    loadedAll () {
      let app = document.querySelector("#app")
      app.style.display="block"
    }
  },
  mounted () {
    let app = document.querySelector("#app")
    app.style.display="block"
  }
}
</script>

<style>
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #212121;
}
:root {
  --mainColor: rgb(245, 186, 0);
}

select {
  /*webkitwidth: 100px;
  background-color: white;
  border:  solid 1px red;
  -webkit-appearance: none;
  /*Firefox */
  -moz-appearance: none;
  /* modern browsers */
  appearance: none;
  border-radius: 0;

}
/*---- notb selectable images -----------*/
img {  
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.img:focus {
    outline: none !important;
}
a {
  cursor: pointer;
}

/*--------- no arrow on input type = number ---------*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.input:focus {
    outline: none !important;
    border:none!important;
}

</style>
