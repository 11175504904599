<template>
<section class="inscription">
    <form class = "insMainSection" @submit="submiting" action="#">
        <div class = "insTitle">
            <span>Récupérez votre compte !</span>
        </div>
        <div class="insIillus"> <img style="width:30px;margin-bottom:20px" src="./image/unlocked.svg"/></div>
        <div class ="insInput">
            <InputI0 placeholder="Entrez email" ref="email" type = "email" />
        </div>
        <div class ="insInput">
            <InputI0 placeholder="Entrez le nouveau mot de passe" ref="password" type = "password" pattern="^.{4,22}$" />
        </div>
        <div class ="insInput">
            <InputI0 placeholder="Confirmez le mot de passe" ref="confirmpw" type = "password" pattern="^.{4,22}$" />
        </div>
        <div @click='save' style="padding-bottom:20px;padding-top:20px" class="insButtonI0" ><ButtonI0 message = "Confirmez" /></div>
    </form>
    <Infos ref="infos" @valEmail='validateEmail'/>
    <LLoading1 :visible="loading"/>
    <AlertG ref='alertG'/>
</section>
</template>
<style src="./inscription.css">
</style>
<script src = "./recoverAccount.js">
</script>
