import SearchBar from './SearchBar'
import SearchBar1 from './SearchBar1'

export default {
  name: 'jNavBar',
  props: {
    state: {
      type: String,
      default: 'none'
    }
  },
  components: {
    SearchBar,
    SearchBar1
  },
  emits:["inscription","connexion","search","messages"],
  data () {
    return {
      connected:this.$store.state.user.connected
    }
  },
  methods: {
    setSearch (val) {
      this.$refs.search.search = val
      this.$refs.search1.search = val
    },
    search (toSearch) {
      if(toSearch.length > 100 ) {
        alert('Recherche trop longue.')
        return
      }
      if(this.state == 'none') {
        if(toSearch != '') this.$store.commit('setObjListSearch',{type:0,search:toSearch})
        this.$router.push({name:'objectList', params: {search:1}})
      }
      else this.$emit('search',toSearch)
    },
    deconnexion () {
      this.setCookie('userId','',0)
      this.setCookie('userName','',0)
      this.setCookie('userTel','',0)
      let user = {id:'',name:'loilo',tel:'',connected:false}
      this.$store.commit('connexion',user)
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  },
  mounted () {
    this.$watch(
      () => this.$store.state.user.connected,
      (newVal) => {
        this.connected = newVal
      })
  }
}
