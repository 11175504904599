<template>
    <div class="jNavbar">
        <div class="jNavbarLogo">
                <div @click="$router.push('/')" class="jNavbarLogo1">
                    <img style="display:none" src='./img/home.svg'/>
                    sassa<span class="jNavbarLColor" style="font-weight:bold;">yer.com</span>
                </div>
                <div v-show="connected">
                    @{{ $store.state.user.name }}
                </div>
                <div v-show="!connected">
                    #Brader des objets
                </div>
        </div>
        <div class="jNavbarTop">
            <div class="jNavbarSearch"><SearchBar ref="search" @search="search" :state='this.state'/></div>
           <div class="jNavbarMenus">
                <div @click="$emit('messages')" v-show="connected" class="jNavbarMenu">
                    <div class="jNavBarMsg">
                        <img src="./img/msg1.svg"/>
                        <span v-show="$store.state.unreadCount.overall > 0" >
                            {{ $store.state.unreadCount.overall }}
                        </span>
                    </div>
                    Messages
                </div>
            </div>
            <div v-show="connected" class="jNavbarMenus">
                <div @click="deconnexion" class="jNavbarMenu">
                    <img src="./img/logout.svg"/>
                    Deconnexion
                </div>
            </div>
            <div v-show="!connected" class="jNavbarMenus">
                <div @click="$emit('connexion')" class="jNavbarMenu">
                    <img src="./img/login.svg"/>
                    Connexion
                </div>
            </div>  
            <div v-show="!connected" class="jNavbarMenus">
                <div @click="$emit('inscription')" class="jNavbarMenu">
                    <img src="./img/user.svg"/>
                    Inscription
                </div>
            </div>
        </div>
    </div>
    <!-- navbar version for smaller screen -->
    <div class="jNavbar1">
        <div class="jNavbar1Logo">
            <div @click="this.$router.push('/')">sassa
                <span class="jNavbarLColor" style="font-weight:bold;">yer.com</span>
            </div>
            <span style="font-size:13px; text-align:right" v-show="!connected">#Brader des objets</span>
            <span style="font-size:13px; text-align:right" v-show="connected">@{{$store.state.user.name}}</span>
        </div>
        <div class="jNavbar1Top">
            <div class="jNavbar1Menu">
                <div><img @click="$router.push('/')" src="./img/box.svg"/></div>
                <div v-show="!connected"><img @click="$emit('inscription')" src="./img/user.svg"/></div>
                <div v-show="!connected"><img @click="$emit('connexion')" src="./img/login.svg"/></div>
                <div v-show="connected">
                    <div @click="$emit('messages')" class="jNavBarMsg">
                        <img src="./img/msg1.svg"/>
                        <span v-show="$store.state.unreadCount.overall > 0" >
                            {{ $store.state.unreadCount.overall }}
                        </span>
                    </div>
                </div>
                <div v-show="connected"><img @click="deconnexion" src="./img/logout.svg"/></div>
            </div>
        </div>
        <div><SearchBar1 ref="search1" @search="search" :state='this.state'/></div>
    </div>
</template>
<style src="./jNavbar.css">
</style>
<script src = "./jNavbar.js">
</script>
