<template>
    <div class="jFooter">
        Copyright 2023. lobjectiviste prod All right reserved.        
        <div>    
            <a><img src="./img/facebook.svg"/></a>
            <a><img src="./img/gmail.svg"/></a>
            <a href='https://wa.me/237652952941' ><img src="./img/whatsapp.svg"/></a>
        </div>
    </div>
</template>
<style>
.jFooter {
    position: absolute;
    bottom: 0px;
    left:0px;
    width:100%;
    background-color: #E3E4E6;
    color:#4D4D4D;
    text-align: center;
    display: flex; justify-content: center;align-items: center;
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 12px;
}
.jFooter > div > a > img {
    width: 20px;
    margin-left: 20px;
    cursor: pointer;
}
/* ---------------- now managment of the responsive ---------------------*/
@media screen and (max-width: 800px){
    .jFooter {
        flex-direction: column-reverse;
    }
    .jFooter > div > img {
        margin: 10px;
        margin-top: 0px;
    }
}
</style>
