import JFooter from '@/components/global/jFooter/JFooter'
import LLoading1 from '@/components/global/lLoading1/LLoading1'

export default {
  name: 'qrMenu',
  components: {
    JFooter,
    LLoading1
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /*toMenu () {
      this.$router.push(this.$store.state.baseUrl+'menus/menu1')
    },*/
    save () {
      let files = this.$refs.file.files
      if(files.length==0) {
        alert('Veuillez choisir un fichier')
        return
      }

      let formData = new FormData()
      formData.append('type', 'saveQrIMenu')
      formData.append('menu', files[0])

      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'qrMenu.php', formData, {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      })
      .then(() => {
        this.loading = false
        alert('Success!')
      })
      .catch((error) => {
        console.log(error)
        this.loading = false  
      })
    }
  }
}
