import ImgUpload from '@/components/global/imgUpload/ImgUpload'
import LLoading from '@/components/global/lLoading/LLoading'
import Visualizer from '@/components/global/visualizer/Visualizer'

export default {
    name:'chatBox',
    components: {
        LLoading,
        ImgUpload,
        Visualizer
    },
    data () {
        return {
            loading:false,
            mois: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet',
            'Aout','Septembre','Octobre','Novembre','Décembre'],
            Display:false,
            page0:true,
            person2:{id:'',img:'',name:''}, // the person to who user is talking
            //messages:[{author:-1,receiver:1,content:"YaouEntre Douala",date:new Date(),type:'img',state:0}],
            message:'',
            discussions:JSON.parse(JSON.stringify(this.$store.state.discussions)),
            unreadCount:this.$store.state.unreadCount,
            myId:this.$store.state.messageId,
            bUrl:this.$store.state.baseUrl + 'images/',
            toSendList:[],
            canIsend: true // this variable allow to have the component sending one request at a time, so it is when one request ended that another can start
        }
    },
    methods: {
        visualize (imgUrl) {
            let toDisplay = imgUrl == '' ? require('./img/profil.svg') : this.bUrl+imgUrl
            this.$refs.visualizer.open([toDisplay])
        },
        loadDiscussion (infos) {
            if(this.$store.state.messageId === -1) return
            if(infos.id === this.myId) return 
            this.person2 = infos
            this.message = infos.msg
            //------ scroll down ---------------------------------------------
            let k = this
            this.$nextTick(()=>{
                k.$refs.msgList.scrollTo({top:2000000})
            })
            //-------- set all the messages read ----------------------------
            this.setRead()
            //------------- display window ----------
            this.page0 = false
            this.Display = true
        },
        enterDisc (key) {
            let messages = this.discussions[key]
            this.person2 = {id:messages[0].person2Id,name:messages[0].person2Name,
                img:messages[0].person2Img }
            this.page0 = false
            //------ scroll down ---------------------------------------------
            let k = this
            this.$nextTick(()=>{
                k.$refs.msgList.scrollTo({top:2000000})
            })
            //-------- set all the messages read ----------------------------
            this.setRead()
        },
        setDate (dat) {
            let d = new Date(dat)
            let min = d.getMinutes()
            min = min < 10 ? 0+''+min : min 
            return d.getHours()+':'+min
        },
        setDay (dat) {
            let d = new Date(dat)
            let d1 = new Date()
            return (d.getFullYear() !== d1.getFullYear() ? d1.getFullYear()+' ' : '' )
            + this.mois[d.getMonth()] +' '+ d.getDate()
          },
        messageSender (type,img) {

            //---- add new message to the frontend --------
            
            let msg=null, d = new Date()
            let formData = new FormData()
            if(type === 'text'){
                //verification
                //let regex2 = /^(.|\n){1,1000}$/
                if (this.message === '' || this.message === ' ') {
                    return
                }
                if (this.message.length > 1000) {
                    alert("message trop long")
                    return
                }
                msg = {author:this.myId,receiver:this.person2.id,content:this.message,
                creationDate:d.getTime(),type:'text',state:0,isAuthor:1}
                formData.append("content",msg.content)
            }
            else {
                msg = {author:this.myId,receiver:this.person2.id,content:img.url,
                creationDate:d.getTime(),type:'img',state:0,isAuthor:1}
                formData.append('image', img.file)
                formData.append("content",'')
            }

            //--------
            if(typeof this.discussions[this.person2.id] === 'undefined') {
                this.discussions[this.person2.id] = []
            }
            this.discussions[this.person2.id].push(msg)
            this.message =''
            
            let k = this
            this.$nextTick(()=>{
                k.$refs.msgList.scrollTo({top:2000000})
            })
            
            //---- setting data ---------------------------------------
            formData.append("aim",'getMessage')
            formData.append("receiver",msg.receiver)
            formData.append("type",msg.type)
            formData.append("sendMessage",'yes')
            formData.append("firstLoad",'no')
            formData.append("user",this.$store.state.user.id)
            formData.append("lastMsgId",this.$store.state.lastMsgId)


            //--- managing the tiket only one request at a time for the component -------
            let maxList = this.toSendList.length
            let myTurn = maxList == 0 ? 0 : this.toSendList[maxList-1].myTurn+1
            this.toSendList.push({myTurn:myTurn,msg:msg})

            let interval = setInterval(()=>{
                if(myTurn == k.toSendList[0].myTurn && k.canIsend ) 
                {// c'est mon tour
                    
                    console.log(k.toSendList[0].myTurn)
                    k.canIsend = false //--- wait the en of the request
                    //console.log("hah c'st mon toiur")
                    k.sendMessage(formData,type,img,msg)
                    clearInterval(interval) // stop
                }
            },100)
        },
        sendMessage (formData,type,img,msg,/*toDelete*/) { 

            const axios = require('axios')
            axios.post(this.$store.state.baseUrl + 'publication.php', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(({data}) => {
                //delete this.discussions[this.person2.id][toDelete]
                msg.state = 1
                // update msg
                this.toSendList.shift()
                /*let j = 0
                for(j=0;j<this.toSendList.length;j++) {
                    this.toSendList[j].myTurn --
                }*/
                if(this.toSendList.length == 0){
                    let newMsg = data.messages
                    if(newMsg.length == 0) return
                    let i, max = newMsg.length
                    let discussions = {...this.$store.state.discussions}
                    for(i=0;i<max;i++) {
                        let id = newMsg[i].person2Id
                        if(typeof discussions[id] === 'undefined' ) discussions[id] = []
                        discussions[id].push({...newMsg[i],state:1}) 
                    }
                    this.$store.commit('setDiscussions',{discussions:discussions,count:this.unreadCount,
                        lastMsgId:data.lastId,firstLoad:'no'})
                    console.log(data.lastId)
                    this.discussions = JSON.parse(JSON.stringify(discussions))
                }
                let k = this
                //---- pile gestion ----------------
                

                /*if(k.toSendList.length == 0){
                    this.$nextTick(()=>{
                        k.discussions = JSON.parse(JSON.stringify(discussions))
                        console.log(k.discussions)
                    })
                }*/
                //----- recursive -----------------
                
                setTimeout(function(){
                    k.canIsend = true
                }, 300)
                //console.log(data[0])
                if(k.toSendList.length == 0){
                    setTimeout(function(){
                        if(k.toSendList.length == 0){
                            k.getMessage()
                        }
                    }, 1000)
                }
            })
            .catch(() => {
                console.log("error....")
                //----- after 1 second we retry -----------------
                //this.message = msg.content
                let k = this
                setTimeout(function(){
                    //console.log(img)
                    k.sendMessage(formData,type,img,msg)
                }, 1000)
            })
        },
        getMessage () {

            //---- if user not connected get out ------
            if(!this.$store.state.user.connected) return

            //----- if its locked, i leave ------------
            if(this.canIsend == false) return
            else this.canIsend = false
            
            //---- if it is the first load --------------
            let firstLoad = this.$store.state.firstLoad
            this.$store.commit('setFirstLoad','no')
           //--- getting msg -----------------
            const axios = require('axios')
            axios.post(this.$store.state.baseUrl + 'publication.php', {
                aim:'getMessage',
                user:this.$store.state.user.id,
                sendMessage:'no',
                firstLoad:firstLoad,
                lastMsgId: this.$store.state.lastMsgId
            })
            .then(({data}) => {
                //console.log(data)
                //console.log(data.lastId)
                    
                let newMsg = data.messages
                if(newMsg.length > 0) {//--- update discussion ---------------
                    let i, max = newMsg.length
                    let discussions = {...this.$store.state.discussions}
                    let count = {...this.$store.state.unreadCount}
                    for(i=0;i<max;i++) {
                        let id = newMsg[i].person2Id
                        if(typeof discussions[id] === 'undefined' ) {
                            discussions[id] = [{...newMsg[i],type:"day"}]
                            count[id] = 0
                        }
                        else {

                            let d = new Date(discussions[id][discussions[id].length-1].creationDate)
                            let d1 = new Date(newMsg[i].creationDate)
                            if( 
                                !(d.getFullYear() === d1.getFullYear() &&
                                d.getMonth() === d1.getMonth() &&
                                d.getDate() === d1.getDate()
                                )
                            ) {
                                discussions[id].push({type:"day",creationDate:newMsg[i].creationDate})
                            }
                        }
                        discussions[id].push({...newMsg[i],state:1})
                        //----- unread counting -------------------------------
                        if(!newMsg[i].isAuthor && newMsg[i].receiverRead === 0) {
                            count[id]++
                            count.overall++
                        }
                    }
                    
                    this.$store.commit('setDiscussions',{discussions:discussions,count:count,
                        lastMsgId:data.lastId, firstLoad:'no'})
                    this.discussions = JSON.parse(JSON.stringify(discussions))
                    this.unreadCount = count
                }
                
                //----- recursive -----------------
                this.canIsend = true //--- now everyone can take it -------
                let k = this
                //console.log(data[0])
                setTimeout(function(){
                    k.getMessage()
                }, 1000)
            })
            .catch((error) => {
                console.log(error)
                this.canIsend = true
                let k = this
                setTimeout(function(){
                    k.getMessage()
                }, 1000)
            })
        },
        setRead () {
            if (this.unreadCount[this.person2.id] === 0) return
            this.unreadCount[this.person2.id] = 0
            this.$store.commit('setUnread',this.unreadCount)
            //--- update-------------------------
            const axios = require('axios')
            axios.post(this.$store.state.baseUrl + 'publication.php', {
                aim:'setRead',
                person2: this.person2.id
            })
            .catch((error) => {
                console.log(error)
            })
        },
        getMyId () {
            //---- if user not connected get out ------
            if(!this.$store.state.user.connected) return
            this.loading = true
            const axios = require('axios')
            axios.post(this.$store.state.baseUrl + 'publication.php', {
                aim:'getMessageId',
                user: this.$store.state.user.id
            })
            .then(({data}) => {
                this.$store.commit('setMessageId',data)
                this.myId = data
                this.loading = false
                //--- load fetch message loop-----------
                this.getMessage()
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },
    mounted () {
        if(this.$store.state.messageId === -1) this.getMyId()
        else this.getMessage()
        
        this.$watch(
        () => this.$store.state.discussions,
        () => {
            let k = this
            this.$nextTick(()=>{
                k.$refs.msgList.scrollTo({top:2000000})
            })
        })

        this.$watch(
            () => this.$store.state.user.connected,
            (newVal) => {
                console.log(newVal)
                this.$store.commit('setDiscussions',{discussions:{},count:{},lastMsgId:0,firstLoad:'yes'})
                this.discussions = {}
                this.unreadCount = {}
                this.page0 = true
                this.Display = false
                if(newVal === true) this.getMyId()
        })
    }
}