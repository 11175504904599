<template>
    <div class="stepsForm"  v-show="Display">
        <div class="stepsFormMain" @touchstart="touching">
            <div class="stepsFormMhead">
                <span :style="{backgroundColor:'rgb(93,93,93)'}"></span>
                <span :style="{backgroundColor:position<=-100 ? 'rgb(93,93,93)':'white'}"></span>
                <span :style="{backgroundColor:position<=-200 ? 'rgb(93,93,93)':'white'}"></span>    
                <div v-show="position < 0" @click="move(1)"><img src="./img/arrow_back.svg"/></div>
                <img @click="close" src="./img/more.svg"/>
            </div>
            <div :style="{ left:Position,transition:transition }" class="stepsFormBody">
                <div v-for="(slide,index) in slides" :key="index" class="stepsFormBElt">
                    <div class="stepsFormT1">{{ slide.title }}</div>
                    <div>{{ slide.subtitle }}</div>
                  
                  <div v-if="slide.city" :id="'IstepsFormP'+type+edit" class="stepsFormCity">
                    <InputSearch ref="stepsFormI" type="Ville" :id="'IstepsForm'+type+edit"  
                    :placeholder="'Saisir la ville'" style="z-index:3" /></div>

                    <div v-for="(select,index) in slide.selects" :key="index" class="stepsFormS">
                        <select v-model="select.value">
                            <option v-for="(option,index) in select.options" :key="index">{{option}}</option>
                        </select>    
                        <img src="./img/down.svg"/>
                    </div>
                    <input v-for="(input,index) in slide.inputs" :key="index" 
                        v-model="input.value"
                        class="stepsFormI" :type="input.type" :placeholder="input.placeholder">
                    <textarea v-for="(textarea,index1) in slide.textareas" :key="index1"
                        v-model="textarea.value"
                        class="stepsFormTa stepsFormI" :placeholder="textarea.placeholder" 
                        type="text"></textarea>
                    <div class="stepsFormImgs">
                        <ImgUpload v-for="(img,indexA) in slide.images" ref="images" :id="type+indexA+edit+infos.id" :name="img.name" :key="indexA" />
                    </div>
                </div>
            </div>
            <div class="stepsFormButt">
                <span :style="{backgroundColor:position==-200 ? '#15a556' : '#E64545'}" 
                @click="validation">{{ position == -200 ? 'Enregistrer' : 'Suivant' }}</span>
            </div>
        </div>
        <AlertG ref="alertG" style="z-index:4"/>
        <AlertG ref="alertGC" @okay="close" style="z-index:4"/>
        <LLoading1 :visible="loading" style="z-index:4"/>
    </div>
</template>
<style src="./stepsForm.css">
</style>

<script src="./stepsForm.js">
</script>
