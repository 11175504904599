<template>
  <div @mouseleave="blurredM" 
          @mouseover="isComp=true" class="inputSearch">
    <div class="inputSearchFirst">
      <div class="inputSearchText">
        <div class="inputSearhTextFirst">
          <input
          :id="'inputToSearch'+type + id" 
          v-model="toSearch" type="text"
          :placeholder="placeholder"
          @focus="isComp=true"
          @blur="blurred"
          autocomplete="off"
          ref="inputElt"
          >
        </div>
      </div>
      <div class="inputSearchButton">
          <img class="inputFirstImg" src="./img/loupe.svg"/>
      </div>
    </div>
    <div ref="first"
      @mouseleave="isComp=false"
      @mouseover="isComp=true"
      v-show="autDisp && isComp" 
      class="inputSearchComple">
      <span @click="selecting(aut)"
          v-for="(aut, index) in autoCompletion" :key="index" >{{ aut.name }}</span>
    </div>
  </div>
</template>
<style src="./inputSearch.css">
</style>
<script src="./inputSearch.js">
</script>
