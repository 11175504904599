export default {
  name: 'lFooter',
  data () {
    return {
      Display:false,
      email:'',
      code:''
    }
  },
  methods: {
    displaying (email) {
      this.Display = true,
      this.email = email
    }
  }
}
