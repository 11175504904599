//import { Http } from '@capacitor-community/http'

export default ({ store, next }) => {

  function loadData () {
    const axios = require('axios')
    //axios.defaults.withCredentials = false
    axios.post(store.state.baseUrl + 'update.php', {
      type:'post',
      firstLoad:1
    })
    .then(({data}) => {
      //axios.defaults.withCredentials = true
      store.commit('loadCommit',data)
      store.commit('loading',false)
    })
    .catch((error) => {
      alert(error)
    })
  }
  if(!store.state.load.loaded) {
    store.commit('loading',true)
    store.commit('setLoaded',true)
    loadData()
    next()
  }
  else {
    next()
  }
}
