<template>
<section v-show="Display" @click="closing" ref='main' class="inscription">
        <form class = "insMainSection" action="#">
            <div class = "insTitle">
                <span>Créez un compte </span>
                <div v-show="DispErr" class="insError">{{error}}</div>
            </div>
            <div class ="insInput">
                <InputI0 placeholder="Entrez votre nom" ref="surname" type = "text"/>
            </div>
            <div class ="insInput">
                <InputI0 placeholder="Entrez num téléphone" ref="contact" type = "number" />
            </div>
            <div class ="insInput">
                <InputI0 placeholder="Entrez un mot de passe" ref="password" type = "password" pattern="^.{4,22}$" />
            </div>
            <div class ="insInput">
                <InputI0 placeholder="Confirmez le mot de passe" ref="confirmpw" type = "password" pattern="^.{4,22}$" />
            </div>
            <div class="insLaw">
                <span class ="insPolicy">By signing up, you confirm that you've read and accepted our <span>Terms of Service</span> and <span>Privacy Policy</span>. </span>
            </div>
            <div @click='save' class="insButtonI0" ><ButtonI0 message = "Continuez" /></div>
            <div class = "insOR">
                <span class ="insPolicy">OR</span>
            </div>
            <div class="insLast">
                <a @click="toConnexion">Vous avez déjà un compte? Connectez vous</a>
            </div>
        </form>
    <Infos ref="infos" @valEmail='validateEmail'/>
    <LLoading1 :visible="loading"/>
    <AlertG ref="alertG" @okay="Display=false"/>
</section>
</template>
<style src="./inscription.css">
</style>
<script src = "./inscription.js">
</script>
