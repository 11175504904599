import datas from '@/assets/datas.json'
export default {
    name: "InputSearch",
    props: {
        placeholder: String,
        id: {
            type: String,
            default:''
        },
        type: {
            type: String,
            default:''
        }
    },
    data() {
        return {
            toSearch: "",
            lastCity:{name:"Yaounde"},
            filters:[],
            autoCompletion: [],
            datas: ['a','aaO','aaa','aaaa','aaaaa'],
            isComp: true,
            state: 0, // 1 means selected sate 0 means nothing selected,
            currIndex: 0, // 36 the index selected
            current:'', // current value selected
            dontClear:true // allow to dont clear toSearch when blurred fired
        };
    },
    computed: {
        autDisp () { return this.autoCompletion.length === 0 ? false:true}
    },
    emits:['newVal'],
    methods: {
        search (word) {
            this.type === 'Ville' ? this.searchCity (word) : this.searchCountry(word)
        },
        searchCity (word) {
            this.autoCompletion = []
            let i = 0,j,k, count = 0
            let cityName = this.removeAccents(word)
            i = this.currIndex
            //console.log(i+" "+datas[i].country_name)
            for(j=0; j<datas[i].states.length; j++){
                if (typeof datas[i].states[j].cities === 'undefined' 
                || datas[i].states[j].cities === null) {
                    continue
                }
                for(k=0; k<datas[i].states[j].cities.length; k++){
                    //console.log(datas[i].states[j].cities[k].city_name)
                    let str = this.removeAccents(datas[i].states[j].cities[k].city_name)
                    if(str.includes(cityName)) {
                        this.autoCompletion.push({name:datas[i].states[j].cities[k].city_name})
                        count++
                    }
                    if (count > 300) break
                }
                if (count > 300) break
            }
            if(this.autoCompletion.length !=0) this.lastCity = this.autoCompletion[0]
        },
        searchCountry (word) {
            this.autoCompletion = []
            let i = 0, count = 0
            let countryName = this.removeAccents(word)
            for(i=0; i<datas.length; i++){
                //console.log(datas[i].country_name)
                let str = this.removeAccents(datas[i].country_name)
                if(str.includes(countryName)) {
                    this.autoCompletion.push({index:i,name:datas[i].country_name})
                    count++
                }
                if (count > 300) break
            }
        },
        removeAccents (str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
        },
        selecting (word) {
            this.toSearch = word.name
            this.current = word.name
            this.$emit('newVal',word.name)
            this.autoCompletion = [word]
            this.isComp = false
        },
        setDefault (cityName) {
            this.toSearch = cityName
            this.current = cityName
            this.lastCity = {name:cityName}
        },
        setCityListByCountryName (countryName) {
            this.searchCountry(countryName)
            this.currIndex = this.autoCompletion[0].index
            this.autoCompletion = []
            return this.currIndex
        },
        blurred () {
            if(this.dontClear == true || this.toSearch == '') return
            //this.toSearch = this.autoCompletion.length == 0 ? this.lastCity.name : this.autoCompletion[0].name
            this.toSearch = this.current
            this.isComp = false
        },
        blurredM () {
            this.isComp = false
            if(this.$refs.inputElt != document.activeElement || this.toSearch == '' ) return // dont have focus
            //this.toSearch = this.autoCompletion.length == 0 ? this.lastCity.name : this.autoCompletion[0].name
            this.toSearch = this.current
        },
        getCity () {
            if(this.toSearch == '') return -1
            return this.autoCompletion.length == 0 ? this.lastCity.name : this.autoCompletion[0].name    
        }
    },
    mounted () {
        this.datas = datas
        let k = this
        let input = document.getElementById("inputToSearch"+this.type+this.id)
        input.addEventListener('input',function(event){
            //alert(event.data)
            let word = event.data === null ? '' : event.data
            if (typeof word === 'undefined' || word === null) word = ''
            k.dontClear = true
            input.blur()
            input.focus()
            k.search(k.toSearch)

        })
    }
}