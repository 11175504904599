<template>
    <div class="dashImage">
          <p v-show="url==''?false:true" 
              @click="clear"
              class="dashCross">
              <img src="./img/more.svg"/>
          </p>
          <ImageUploader
              :id="'uploader'+ name+id"
              :ref="'imUploader'+ name+id"
              :debug="1"
              :maxWidth="612"
              :maxHeight="612"
              :autoRotate = true
              :quality = "1"
              outputFormat="blob"
              :preview='false'
              @input="preview($event)"
              style="display:none"
          />
          <label v-show="url==''?true:false" :for="'uploader' + name+id" class="custom-file-upload dashLabel">
              <img src="./img/picture.svg">
              <div class="dashPlus"><img src="./img/plus.svg"/></div>
          </label>
          <label class="dashImgUp" 
            :style="{backgroundImage:'url('+url+')'}"
            :for="'uploader' + name+id" v-show="url==''?false:true">
            <img/>
          </label>
      </div>
</template>
<style src="./imgUpload.css">
</style>
<script src = "./imgUpload.js">
</script>
