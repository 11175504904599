<template>
  <div v-show="Display" class="chatBox">
    <header>
      <img v-show="!page0" @click="page0=true" class="chatBoxBack" src="./img/arrow_back.svg">
      <div :style="{backgroundImage:'url('+
          (person2.img != '' ? bUrl+person2.img : require('./img/profil.svg'))
        +')'}" 
        @click="visualize(person2.img)"
        v-show="!page0" class="chatBoxProfile">
      </div>
      <span v-show="!page0" class="chatBoxTitle">{{ person2.name }}</span>
      <span v-show="page0" class="chatBoxTitle" style="text-align:center">Discussions</span>
      <div class="chatBoxCross"><img @click="Display=false" src="./img/cross.svg"/></div>
    </header>
    <div ref="msgList" v-show="!page0" class="chatBoxMessages">
      <div v-for="(msg,index) in discussions[person2.id]"
        :key="index" 
        :class="msg.isAuthor ? 'chatBoxMessage' : 'chatBoxMessage chatBoxMessageR'"
        v-show="msg.type=='img' || msg.type=='text' || msg.type=='day' "
      > 
          <div v-show="msg.type=='text'">{{ msg.content }}
            <span>{{ setDate(msg.creationDate) }}
              <img v-show="msg.state==0" src="./img/clock.svg"/>
              <img v-show="msg.state==1" src="./img/checked.svg"/>
            </span>
          </div>
          <span class="chatBoxMImg" v-show="msg.type=='img'"
            :style="{backgroundImage:'url('+$store.state.baseUrl+'images/'+msg.content+')'}"
            @click="visualize(msg.content)"
          >
              <img v-show="msg.state==0" src="./img/clock.svg"/>
              <img v-show="msg.state==1" src="./img/checked.svg"/>
          </span>
          <p v-show="msg.type=='day'"><span>{{ setDay(msg.creationDate) }}</span></p>
      </div>
    </div>
    <footer v-show="!page0">
      <textarea v-model="message" placeholder="Entrer un message"></textarea>
      <label for="uploaderchatBoxSendImg0" ><img src="./img/clip.svg"/></label>
      <ImgUpload @imgInfos="(infos)=>{messageSender('img',infos)}" v-show="false" id="0" name="chatBoxSendImg" />
      <img @click="messageSender('text',null)" src="./img/mail.svg"/>
    </footer>
    <div v-show="page0" class="chatBoxDisc">
      <div @click="enterDisc(key)" v-for="(disc,key,index) in discussions" :key="index" class="chatBoxDiscElt">
        <div :style="{backgroundImage:'url('+
          (disc[disc.length-1].person2Img != '' ? bUrl+disc[0].person2Img : require('./img/profil.svg'))
        +')'}"
          class="chatBoxDiscImg"
        >
        </div>
        <div><div>{{ disc[disc.length-1].person2Name }}</div><span>{{ disc[disc.length-1].type=='text' ? disc[disc.length-1].content.slice(0,30)+'...' : '[:)]' }}</span></div>
        <span v-show="unreadCount[key]>0" class="chatBoxDCount">{{ unreadCount[key] }}</span>
      </div>
    </div>
    <Visualizer style="z-index:2" ref="visualizer"/>
    <LLoading :visible="loading"/>
  </div>
</template>
<style src="./chatBox.css">
</style>
<script src = "./chatBox.js">
</script>