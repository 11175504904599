import InputI0 from '@/components/inscription/InputI0.vue'
import ButtonI0 from '@/components/inscription/ButtonI0.vue'
import ButtonI1 from '@/components/inscription/ButtonI1.vue'
import DropDownList from '@/components/inscription/DropDownList.vue'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
export default {
  name: 'Connexion',
  components: {
    InputI0,
    ButtonI0,
    ButtonI1,
    DropDownList,
    LLoading1,
    //---- previous page ------
    prevPage:''
  },
  data () {
    return {
      loading:false,
      Display:false,
      DispErr: false,
      error:''
    }
  },
  emits:["inscription"],
  methods: {
    closing (event) {
      if( event.target == this.$refs.main ) this.Display = false
    },
    toInscription () {
      this.$emit('inscription')
      this.Display = false
    },
    loggin () {
      if (!this.validation()) return
      this.DispErr = false
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'inscription.php', {
        type:'connexion',
        contact: this.$refs.contact.message,
        password: this.$refs.password.message
      })
        .then(({data}) => {
          this.loading = false
          if(data == 'notExist') {
            this.error = 'Compte inexistant'
            this.DispErr =true
          }
          else {
            this.setCookie('userId',data.id,5)
            this.setCookie('userName',data.name,5)
            this.setCookie('userTel',data.tel,5)
            this.setCookie('userImg',data.img,5)
            let user = {id:data.id,name:data.name,tel:data.tel,img:data.img,connected:true}
            this.$store.commit('connexion',user)
            this.Display = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validation () {
      this.DispErr = false
      let regex2 = /^[0-9]{6,11}.$/
      if (!regex2.test(this.$refs.contact.message)) {
        this.error = 'numéro télépnone incorrect'
        this.DispErr = true
        return false
      }
      if (this.$refs.password.message == '') {
        this.error = 'mot de passe incorrect'
        this.DispErr = true
        return false
      }
      return true
    },
    //-- cookie useful functions ------------
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }
}
