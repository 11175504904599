export default ({ /*to, from,*/store, next }) => {
    // Your custom if statement
    function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    if(getCookie("userId") != "" && !store.state.user.connected) {
      let user = {id:getCookie("userId"),name:getCookie("userName"),tel:getCookie("userTel"),img:getCookie("userImg"),connected:true}
      store.commit('connexion',user)
    }
    //console.log("lalala"+store.state.user.connected)
    next()
  }