import JNavbar from '@/components/global/jNavbar/JNavbar'
import JFooter from '@/components/global/jFooter/JFooter'
import Service from '@/components/service/Service'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import ScrollTop from '@/components/global/scrollTop/ScrollTop'
import InputSearch from '@/components/global/inputSearch/InputSearch'
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import RecoverAccount from '@/components/inscription/RecoverAccount'
import ChatBox from '@/components/global/chatBox/ChatBox'

export default {
  name: 'objectList',
  components: {
    JNavbar,
    JFooter,
    Service,
    LLoading1,
    ScrollTop,
    InputSearch,
    Inscription,
    Connexion,
    RecoverAccount,
    ChatBox
  },
  data () {
    return {
      loading:true,
      infos:{ 
          list:[],scroll:0,nbrRow:[0],
          city:'Yaounde',type:'Téléphone',search:''
        }
    }
 },
 computed: {
  moreVisible () {
    return 16*this.infos.nbrRow.length < this.infos.list.length ? true : false
  }
 },
 methods: {
  scrollingTop () {
    this.$refs.main.scrollTo({top:0,behavior:'smooth'})
  },
  showScrollTop () {
    this.$refs.main.scrollTop > 700 ? this.$refs.scrollTop.show() : this.$refs.scrollTop.hide()
  },
  moreResults () {
    if(this.moreVisible) this.infos.nbrRow = this.infos.nbrRow.concat(this.infos.nbrRow)
  },
  search (index,value) {
    this.infos[index] = value
    //console.log(index+':'+value)
    this.getObjects()
  },
  removeAccents (str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  getObjects () {
    this.loading = true
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'publication.php', {
      aim:'sort',
      city:this.infos.city,
      type:this.infos.type,
      search:this.infos.search
    })
      .then(({data}) => {
        //console.log(data)
        this.infos.scroll = 0
        this.infos.nbrRow = [0]
        this.$refs.main.scrollTo({top:0})
        //--- updating data
        let k = this
        k.infos.list = []
        this.$nextTick(()=>{
          k.infos.list = data
        })
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
  }
 },
 beforeRouteEnter(to, from, next) {
  next ( vm => {
      vm.$refs.objectListCities.toSearch=vm.$store.state.objectList.city
      vm.$refs.navbar.setSearch(vm.$store.state.objectList.search)
      if(vm.$store.state.objectList.created && !vm.$store.state.objectList.isResearch ) {
        vm.infos = vm.$store.state.objectList
        vm.loading = false
        return false
      }
      else {
        //vm.infos.type = typeof vm.$route.params.type != 'undefined' ? vm.$route.params.type : 'Téléphone'
        //vm.infos.search = typeof vm.$route.params.search != 'undefined' ? vm.$route.params.search : ''
        vm.infos.type = vm.$store.state.objectList.type
        vm.infos.search = vm.$store.state.objectList.search
        vm.$refs.navbar.setSearch(vm.infos.search)
        vm.getObjects()
        vm.$store.commit('noResearch')
      }
    })
  },
  beforeRouteLeave() {
      let newInfos = {...this.infos}
      newInfos.created = true
      newInfos.scroll = this.$refs.main.scrollTop
      this.$store.commit('setObjectList',newInfos)
  },
  updated () {
    window.scrollTo(0,0)
    setTimeout(()=> {this.$refs.main.scrollTo({top:this.infos.scroll,behavior:'smooth'})},0)
  }
}
