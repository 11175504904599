import JNavbar from '@/components/global/jNavbar/JNavbar'
import JFooter from '@/components/global/jFooter/JFooter'
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import RecoverAccount from '@/components/inscription/RecoverAccount'
import MyProfile from '@/components/global/myProfile/MyProfile'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import LLoading from '@/components/global/lLoading/LLoading'
import Contact from '@/components/global/contact/Contact'
import ChatBox from '@/components/global/chatBox/ChatBox'

export default {
  name: 'objectList',
  components: {
    JNavbar,
    JFooter,
    Inscription,
    Connexion,
    RecoverAccount,
    MyProfile,
    LLoading1,
    LLoading,
    Contact,
    ChatBox
  },
  data () {
    return {
      loading1:false,
      loading:false,
      infos:{creationDate:'2000-01-01'},
      images:[],
      mois: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet',
      'Aout','Septembre','Octobre','Novembre','Décembre']
    }
 },
 computed: {
  iWanBuy () {
    return `Bonjour, j'aimerai acheter "${this.infos.description}" `
  }
 },
 methods: {
  setDate (dat) {
    let d = new Date(dat)
    return d.getDate()+' '+this.mois[d.getMonth()] +' '+ d.getFullYear()
  },
  setImages () {
    let bUrl = this.$store.state.baseUrl + 'images/'
    if(this.infos.img0 != '' ) this.images.push(bUrl+this.infos.img0)
    if(this.infos.img1 != '' ) this.images.push(bUrl+this.infos.img1)
    if(this.infos.img2 != '' ) this.images.push(bUrl+this.infos.img2)
    if(this.infos.img3 != '' ) this.images.push(bUrl+this.infos.img3)
  },
  showContact () {
    if(this.$store.state.user.connected) this.$refs.contact.displaying()
    else this.$refs.connexion.Display=true
  },
  startChat (msg) {
    if(!this.$store.state.user.connected) {
      this.$refs.connexion.Display = true
      return
    }
    this.$refs.chatBox.loadDiscussion({id:this.infos.userId,img:this.infos.userImg,
      name:this.infos.creator,msg:msg})
  },
  getObject () {
    this.loading1 = true
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'publication.php', {
      aim:'getOne',
      id:this.$route.params.id
    })
      .then(({data}) => {
        this.infos = {...data}
        this.setImages()
        this.loading1 = false
      })
      .catch((error) => {
        console.log(error)
        this.loading1 = false
      })
  }
 },
 mounted () {

    if(this.$store.state.currentObject.id != -1 ) {
      this.infos = this.$store.state.currentObject
      this.setImages()
    }
    else this.getObject()
  }
}
