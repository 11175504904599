<template>
<div class="imagePreloader_components">
  <img 
  v-for="(sc,index) in srcs"
  :src="require(sc+'')"
  @load="loaded"
  :key="index" 
  >
</div>
</template>

<script>
export default {
  props:{
    srcs: Array
  },
  data() {
    return  {
      loadedCount: 0
    }
  },
  methods: {
    loaded() {
      this.loadedCount++
      if(this.loadedCount === this.srcs.length) this.$emit('loaded-all')
    }
  }
}
</script>

<style>
.imagePreloader_components {
  z-index: -1;
  width: 0px;
  height: 0px;
  position: fixed;
  overflow: hidden;
}
</style>