export default {
  name: 'lLoading1',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    Display () {
      return this.visible ? 'flex' : 'none'
    }
  }
}
