<template>
    <div class="landing">
        <div class="landingBody">
            <div class="landingBMain">
                <div class="landingMLeft">
                    <h4><img src="./img/list.svg"/>Categories</h4>
                    <div>
                        <span v-for="(elt,index) in $store.state.types" 
                        @click="toObjectList(elt)"
                        :key="index">{{ elt }}</span>
                    </div>
                </div>
                <div class="landingMRight"  style="z-index:0">
                    <RCaroussel style="z-index:-1; margin-bottom:20px"/>
                    <div class="landingToForm">
                        <div class="landingTFLeft">
                            <div class="landingTFElt">
                                <div>
                                    <span>Publier un objet</span>
                                    Vous avez un objet qui ne vous est plus vraiment utile et que vous aimeriez vendre même à bas prix ?
                                </div>
                                <span><span @click="$refs.stepsFormLost.Display=true">Publier</span></span>
                            </div>
                        </div>
                    </div>



                <div class="landingMRightA" v-show="connected">
                    <div v-show="eltsList.length > 0">
                        <span>Mes objects</span>
                        <div v-for="(elt,index) in eltsList" :key="index" class="landingMRElt">
                            <div class="landingMREltInfos">
                            <div :style="{backgroundImage:'url('+setImage(elt)+')'}" class="landingMREltImg">

                            </div>
                            <div>
                                <span class="landingMRCity">{{ elt.description.slice(0,25) }}</span>
                                <span>{{ elt.city+', '+ elt.price }}FCFA</span>
                                <span>{{ elt.type }}</span>
                            </div>
                            </div>
                            <div class="landingMREltMenu">
                                <div @click="closing(index)"  class="landingMRElTFermer">Fermer</div>
                                <div @click="edit(elt)">Editer</div>
                                <div @click="deleting(index)">Suppr</div>
                            </div>
                        </div>
                    </div>

                    <div v-show="eltsClosed.length > 0">
                        <span>Cas résolus</span>
                        <div v-for="(closed,index) in eltsClosed" :key="index" class="landingMRElt">
                            <div class="landingMREltInfos">
                                <div style="backgroundColor:orange" 
                                :style="{backgroundImage:'url('+setImage(closed)+')'}"
                                class="landingMREltImg">

                                </div>
                                <div>
                                    <span class="landingMRCity">{{ closed.description.slice(0,25) }}</span>
                                    <span>{{ closed.city+', '+closed.price }}FCFA</span>
                                    <span>{{ closed.type }}</span>
                                </div>
                            </div>
                            <div style="font-size:12px" class="landingMREltMenu">
                                N'est plus disponible
                            </div>
                        </div>
                    </div>

                    <div v-show="$store.state.user.connected" class="landingProfile">
                        <div :style="{backgroundImage:'url('+
                        ($store.state.user.img != '' ? bUrl+$store.state.user.img : require('./img/user.svg'))
                        +')'}"  
                        class="landingProfileImg">
                            <label for="uploaderlandingSendImg0" ><img src="./img/exchange.svg"/></label>
                            <ImgUpload @imgInfos="(infos)=>{changeProfile(infos)}" v-show="false" id="0" name="landingSendImg" />
                            <LLoading :visible="loadProfile" style="border-radius:10em" />
                        </div>
                        <div class="landingProfileInfos">
                            <span>{{ $store.state.user.name.toUpperCase() }}</span>
                            <div>Welcome to sassayer.com</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <JFooter/>
        <JNavbar 
            @inscription='$refs.inscription.Display=true'
            @connexion='$refs.connexion.Display=true'
            @messages='$refs.chatBox.Display=true'
        />            
        <StepsForm ref='stepsFormLost' type="lost" @connexion='$refs.connexion.Display=true' @newElt='getObjects'/>
        <StepsForm ref='stepsFormEditL' type="lost" edit="yes"/>
        <Inscription ref='inscription' @connexion='$refs.connexion.Display=true'/>
        <Connexion ref='connexion' @inscription='$refs.inscription.Display=true'/>
        <Confirm @clicked="(verdict)=>deleteMe(toDeleteIndex,verdict)" ref="confirm"/>
        <Confirm @clicked="(verdict)=>closeMe(toCloseIndex,verdict)" ref="confirm1"/>
        
        <ChatBox ref="chatBox"/>

        <LLoading1 :visible='loading'/>
    </div>
</template>
<style src="./landing.css">
</style>
<script src = "./landing.js">
</script>
