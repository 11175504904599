export default {
  name: 'lLoading',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '20px'
    },
    image: {
      type: String,
      default: 'loader1.svg'
    }
  },
  computed: {
    Display () {
      return this.visible ? 'flex' : 'none'
    }
  }
}
