<template>
    <div ref="main" @scroll="showScrollTop" class="objectList">
        <div style="z-index:0" class="objectListBody">
            <div class="objectListSearch">
                <div style="z-index:1" class="objectListSHead">
                    <select class="objectListType"
                     @change="search('type',infos.type)" v-model="infos.type">
                        <option v-for="(option,index) in this.$store.state.types" :key="index">
                            {{option}}
                        </option>
                    </select>
                    <div ref="objectListInputS" class="objectListCities">
                        <InputSearch ref="objectListCities" type="Ville" :id="'objectList'"  
                            @newVal="(val)=>search('city',val)" :placeholder="'Saisir la ville'" />
                    </div>
                </div>
                <div style="z-index:0" class="servicesMain">
                    <h5>Liste des objets trouvés</h5>
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='4'
                        class="landSlider0"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='3'
                        class="landSlider1"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='2'
                        class="landSlider2"
                    />
                    <Service
                        v-for="(row,i) in infos.nbrRow" :key="i"
                        :elements='infos.list.slice(16*i,16+16*i)' 
                        :amount='1'
                        class="landSlider3"
                    />

                    <div class="objectListMore">
                        <span v-show="moreVisible" @click="moreResults"> Plus de résultats</span>
                    </div>
                </div>
            </div>
        

        </div>

        <div class="objectListFooter"><JFooter/></div>
        <JNavbar
            ref='navbar'
            state="objectList"
            @search="(toSearch)=>search('search',toSearch)"
            @inscription='$refs.inscription.Display=true'
            @connexion='$refs.connexion.Display=true'
            @messages='$refs.chatBox.Display=true'
        />
        <ChatBox ref="chatBox"/>
        <Inscription ref='inscription' @connexion='$refs.connexion.Display=true'/>
        <Connexion ref='connexion' @inscription='$refs.inscription.Display=true'/>

        <ScrollTop ref="scrollTop" @click="scrollingTop" />
        <LLoading1 :visible="loading"/>
    </div>
</template>
<style src="./objectList.css">
</style>
<script src = "./objectList.js">
</script>
