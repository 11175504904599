<template>
    <div class="service1Elements">
        <div class="service1Elt" >
            <LPublication
            class="service1Zoom"
            :style="{width:(100/amount -2)+'%',marginBottom:amount==1 ? '20px':'60px'}"
            v-for="(elt,index) in elements"
            :datas='elt'
            :key="index"
            @visualize="(images) => $emit('visualize',images)"
            @contact="$emit('contact')"
            />
        </div>
    </div>
</template>
<style src="./service.css">
</style>
<script src = "./service.js">
</script>
