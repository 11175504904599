export default {
  name: 'lFooter',
  props: {
    display:  Boolean
  },
  data () {
    return {
      Display: this.display,
      text:`Vous serez un guide qui pourra etre engagé pour accompagner 
      un client et lui proposer des logement ou des terrains, vous devez donc: <br><br>
      <b>1.</b> Localiser et entrer le <b>quartier</b> pour lequel vous serez guide. 
      <br><br>
      <b>2.</b> Choisir si vous representerez une <b>entreprise</b> de l'immoblier ou si vous ete une
      <b>personne</b> libre.
      <br><br>
      <b>3.</b> Enfin Entrer le <b>salaire</b> que vous estimez valoir
      pour une visite en tant que guide.`
    }
  },
  emits:['okay'],
  methods: {
    displaying(text) {
      this.text = text
      this.Display = true
    },
    clicked () {
      this.Display=false
      this.$emit('okay')
    }
  }
}
