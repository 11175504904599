<template>
    <div @click="cliked" v-show="Display" class="contact">
        <div ref='contactMain' class="contactBox">
            <div ref='contactMain' class="contactImg">
                <div><img src="./img/profile1.svg"/></div>
            </div>
            <div class="contactName">{{ infos.name }}</div>
            <div>{{ infos.type }}</div>
            <div class="contactButton">
                <div ref='contactTel' @click="copyTel" @mouseleave="copied=false" >
                    <img v-show="!copied" src="./img/phone.svg"/>{{ copied ? 'Copié !':infos.contact }}
                </div>
            </div>
        </div>
    </div>
</template>
<style src="./contact.css">
</style>
<script src = "./contact.js">
</script>
