<template>
    <div v-show="Display" class="infos">
        <div class="infosBox">
            <div @click="Display=false" class="infosCross"><img src="./img/more.svg"/></div>
            <h3>Vérification de l'email</h3>
            <div>Veuillez entrer le code à 4 chiffres envoyé à 
                <a>{{email}}</a>
            </div>
            <div class="infosInput"><input v-model="code" type="number" min="1000" max="9999"/></div>
            <div>Vous n'avez pas reçu le code ?  
                <a @click="Display=false" style="color:rgb(40, 121, 255);">reéssayé</a>
            </div>
            <div><div @click="$emit('valEmail',code)" >Valider</div></div>
        </div>
    </div>
</template>
<style src="./infos.css">
</style>
<script src = "./infos.js">
</script>
