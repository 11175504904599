import Visualizer from '@/components/global/visualizer/Visualizer'

export default {
  name: 'MyProfile',
  components: {
    Visualizer
  },
  props: {
    message: String,
    images:{
      type:Array,
      default:[]
    }
  },
  data () {
    return {
      position: 0,
      elements: [{image:'temoin.png', message:"Ramav production est une entreprise audio visuelle et prestation de servive, créée en 2016 et situé au coeur de la ville de Yaoundé au Cameroun, avec des représentations dans les autres villes du pays. L'entière satisfaction du client est notre objectif. De ce fait toutes nos actions sont résolument... "},
        {image:'images.jpeg',message:'Ramav fourni des services extraordinares, vraiment satisfait du résultat'},
        {image:'temoin.png',message:'Ramav is an oustanding .....'}
      ]
    }
  },
  methods: {
    visualize (images) {
      this.$refs.visualizer.open(images)
    },
    move (i) {
      if ((this.position >= (-this.numb*100 + 200) && i === -1) || (this.position < 0 && i === 1)) {
        this.position += i * 100
      }
      let index = Math.abs(this.position)/100
      this.$emit('message',this.elements[index].message)
    }
  },
  computed: {
    Position () {
      return this.position + '%'
    },
    numb () {
      return this.images.length
    }
  }
}
