export default {
  name: 'rCaroussel',
  props: {
    message: String
  },
  computed: {
    FWidth () {
      return this.position == '0%' || this.position == '0.01%' ? '18px' : '13px'
    },
    SWidth () {
      return this.position == '-100%' || this.position == '100.01%' ? '18px' : '13px'
    },
    TWidth () {
      return this.position == '-200%' || this.position == '200.01%' ? '18px' : '13px'
    }
  },
  methods: {
    changePosition: async (k,newP)=> {
      function waitDelay(t){
        return new Promise((resolve) => {
            setTimeout(resolve, t);
        })
      }
      k.position = (newP+0.01)+"%"
      await waitDelay(4000)
      k.position = (newP)+"%"
    },
    reset: async (k)=> {
      function waitDelay(t){
        return new Promise((resolve) => {
            setTimeout(resolve, t);
        })
      }
      k.transition = 'none'
      k.position = "0%"
      await waitDelay(100)
      k.transition = "left 1s"
      k.f(k)
    },
    f(k) {setTimeout(function(){
      if (k.position == '0%') {
        k.position = '-100%'
        k.f(k)
      }
      else if(k.position == '-100%') {
        k.position = '-200%'
        k.f(k)
      }
      else if(k.position == '-200%') {
        k.reset(k)
      }
      else k.f(k)
  }, 4000)
}
  },
  data () {
    return {
      position: '0%',
      aName: 'example',
      transition: 'left 1s'
    }
  },
  mounted () {
    this.f(this)
  }
}
