<template>
  <div class="rCaroussel">
    <div class="rCarousselMain">
        <div
          class="rCarousselContent"
          :style="{ left:position,transition:transition }"
        >
          <div id="rCC1" class="rCarousselCont ">
            <div>
              Faite de l'argent rapidement ! 
            </div>
          </div>
          <div id="rCC2" class="rCarousselCont">
            <div>
              Trouvez un acheteur, quelqu'un a besoin de votre objet.
            </div>
          </div>
          <div id="rCC3" class="rCarousselCont">
            <div>
                Pas de scammer, construisons une communauté saine pour des echanges avec bénéfice mutuelle.
            </div>
          </div>
        </div>
      </div>
      <div class="rCarousselMenu">
        <div :style="{ width:FWidth }"  @click="changePosition(this,0)"></div>
        <div :style="{ width:SWidth }" @click="changePosition(this,-100)"></div>
        <div :style="{ width:TWidth }" @click="changePosition(this,-200)"></div>
      </div>
  </div>
</template>
<style src="./rCaroussel.css">
</style>
<script src="./rCaroussel.js">
</script>
