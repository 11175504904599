import LPublication from '../lPublication/LPublication'
export default {
  name: 'service',
  props: {
    amount: {
      type: Number,
      default: 4
    },
    start: {
      type: Number,
      default: 0
    },
    elements: {
      type:Array,
      default:[]
    }
  },
  components: {
    LPublication
  },
  data () {
    return {
      /*elements:[{title:'Hotel', description:'Lorem ipsum dolor sit amet concetetur discipin elit',image:'s8.png'},                    
      {title:'Maison à louer', description:'Film entreprise/ Film institution/ Reportage d\'activité video/ Communication interne/ Video de voeux',image:'s2.png'},
      {title:'Maison à louer', description:'Temoignage/ Film portrait/ Publireportage',image:'s6.png'},
      {title:'Terrain en vente', description:'',image:'s1.png'},
      {title:'Maison à louer', description:'Video 3D/ Infographie video/ Stop motion',image:'s4.png'},
      {title:'Maison à louer', description:'Conference de presse / Assemblee/ Colloque de cérémonie/ Formation webinaire/ Forum virtuel interview/ Master class/ Concert',image:'s8.png'},
      {title:'Maison à louer', description:'Spectacle live/ Video conference/ Teleconference/ Livestreaming/ Live sur grad écran',image:'s2.png'},
      {title:'Maison à louer', description:'Presentation application/ Site web/ Produit/ Service/ Testimoniaux',image:'s6.png'},
      {title:'Maison à louer', description:'Location photo/ Location video/Location materielle ausiovisuelle/ Location camera/Location lumiere',image:'s4.png'},
      {title:'Maison à louer', description:'Webserie',image:'s8.png'},
      {title:'Terrain en vente', description:'Impression offset/ Impression numérique/ Grand format/ Serigraphie et broderie/ Marquage sur tout support',image:'s2.png'},
      {title:'Terrain en vente', description:'Webserie/ Video de formation/ Animation pédagogique/ Film de vulgarisation',image:'s6.png'},
      {title:'Terrain en vente', description:'Conception maquette/ Developpement d\'application web/ Developpement de site web',image:'s4.png'},
      {title:'Hotel', description:'Banderolle/ Rolle-up/ T-shirt/ Flyers/ Agenda/ Calendrier/ Poster géant/ carte de visite',image:'s8.png'},
      {title:'Hotel', description:'',image:'s2.png'}
    ]*/
    }
  }
}
