import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
import middleware from "@grafikri/vue-middleware"
const store = createStore({
    state () {
      return {
        //baseUrl: 'http://localhost:3000/',
        //baseUrl: 'http://192.168.43.251:3000/',
        baseUrl: 'https://sassayer.com/',
        types:["Téléphone","Ordinateur","Véhicule","Vêtements","Accessoire","Meuble","Appareil électronique",
        "Autres"],
        user: {
          connected: false,
          id:'',
          name:'',
          tel:'',
          img:''
        },
        userObject: {
          created:false,
          eltActive:[],
          eltClosed:[]
        },
        currentObject:{id:-1},
        objectList:{
          isResearch:true,
          created:false,
          list:[],
          type:'Téléphone',
          srcoll:0,
          nbrRow:[0],
          city:'Yaounde',
          search:''
        },
        discussions:{},
        unreadCount:{overall:0},
        firstLoad:'yes', // is messages already loaded or not
        lastMsgId:0,
        messageId:-1
      }
    },
    mutations: {
      connexion (state,playload) {
        state.user.connected = playload.connected
        state.user.id = playload.id
        state.user.name = playload.name
        state.user.tel = playload.tel
        state.user.img = playload.img  
      },
      saveUserImg (state,playload) {
        state.user.img = playload
      },
      setCurrentObject (state,playload) {
        state.currentObject = playload
      },
      setUserObject (state,playload) {
        state.userObject.created = true 
        state.userObject.eltActive = playload.eltActive
        state.userObject.eltClosed = playload.eltClosed
      },
      setObjectList (state,playload) {
        state.objectList.created = playload.created
        state.objectList.list = playload.list
        state.objectList.type = playload.type
        state.objectList.scroll = playload.scroll
        state.objectList.nbrRow = playload.nbrRow
        state.objectList.city = playload.city
        state.objectList.search = playload.search
      },
      setObjListSearch (state,playload) {
        state.objectList.isResearch = true
        if(playload.type != 0) state.objectList.type = playload.type
        if(playload.search != 0) state.objectList.search = playload.search 
      },
      noResearch (state) {
        state.objectList.isResearch = false
      },
      setDiscussions (state,playload) {
        state.discussions = playload.discussions
        state.unreadCount = playload.count
        state.firstLoad = playload.firstLoad
        state.lastMsgId = playload.lastMsgId
      },
      setFirstLoad (state,playload) {
        state.firstLoad = playload
      },
      setUnread (state,playload) {
        state.unreadCount = playload
      },
      setMessageId (state,playload) {
        state.messageId = playload
      }
    }
  })

const app = createApp(App)
router.beforeEach(middleware({store}))
app.use(router)
app.use(store)
app.mount('#app')


//ALTER DATABASE brader CHARACTER SET = utf8mb4 COLLATE = utf8mb4_unicode_ci;
//ALTER TABLE Message CONVERT TO CHARACTER SET utf8mb4 COLLATE utf8mb4_unicode_ci;
//ALTER TABLE Message MODIFY content TEXT CHARSET utf8mb4;