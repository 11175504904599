<template>
  <div class="Myprofile">
    <div class="profileMain">
        <div
          class="profileContent"
          :style="{ left:Position,width:(numb*100)+'%' }"
          @click="visualize(images)"
        >
          <div v-for="(image,index) in images" :style="{width:100/numb+'%'}" :key="index" class="profileCont">
            <img class="profileContImg" :src="image">
          </div>
        </div>
      </div>
      <span class="myPButton myButtonL" @click="move(1)"><img src="./back.svg"/></span>
      <span class="myPButton myButtonR" @click="move(-1)"><img src="./next.svg"/></span>
      <Visualizer style="z-index:2" ref="visualizer"/>
  </div>
</template>
<style src="./myProfile.css">
</style>
<script src="./myProfile.js">
</script>
