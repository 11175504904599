<template>
  <div :style="{width:Display,opacity:Opacity}" class="visualizer">
    <div 
      draggable="false"
      @touchstart="dragStart"
      @mousedown="dragStart"
      @mouseup ="dragEnd"
      @touchend="dragEnd"
      @mousemove="dragging"
      @touchmove="dragging"
      :style="{ left:Position,transition:transition, width:(100*elts.length)+'%' }"
      class="visualContent"
    >
      <div v-for="(elt,index) in elts" :key="index"><img draggable="false" :src="elt"/></div>
    </div>
    <div class="visualPoints">
      <span v-for="(elt,index) in elts" :key="index"></span>
    </div>
    <div @click="close" class="visualCross"><img src="./more.svg"/></div>
  </div>
</template>
<script>
export default {
    name: 'visualizer',
    data () {
        return {
          Display:'0px',
          Opacity: 0,
          position: '0',
          transition: 'left 0.3s',
          startPoint: 0,
          prevPositon: 0,
          min: -100,
          offset:100,
          elts:[/*{img:'chambre.jpg'},{img:'phone.svg'},{img:'star.svg'},{img:'quote.svg'}*/]
        }
    },
    computed: {
      Position () {
        return this.position+'%'
      }
    },
    methods: {
      dragStart (event) {
        event.preventDefault()
        this.startPoint = event.type == 'touchstart' ? event.touches[0].pageX : event.pageX
        this.prevPositon = this.position
      },
      dragging (event) {
        if(this.startPoint != 0) {
          let offset = event.type == 'touchmove' ? event.touches[0].pageX : event.pageX
          //alert(offset)
          //------------------------------------------------------------
          let a = offset - this.startPoint
          a = a/window.innerWidth*60
          let newPosition = parseFloat(this.position) + a
          if(newPosition >= this.min && newPosition <= 0) this.position = newPosition
        }
      },
      dragEnd () {
        let dragDistance = this.prevPositon - this.position
        //------ multiplicateur --------------------------
        let m = 1
        if(Math.abs(dragDistance) > 140 ) m = 2
        else if(Math.abs(dragDistance) > 200 ) m = 3
        else if(Math.abs(dragDistance) > 230 ) m = 4

        let r = this.prevPositon
        if( dragDistance < 0 ) r = this.prevPositon + m*this.offset
        else if(dragDistance > 0) r = this.prevPositon - m*this.offset

        if(r <= this.min) this.position = this.min
        else if(r >= 0) this.position = 0
        else this.position = r - r%this.offset
        this.startPoint = 0
      },
      close () {
        this.Display = '0px'
        this.Opacity = 0
        this.position = '0'
        this.startPoint = 0
        this.prevPositon = 0
      },
      open (images) {
        this.elts = images
        this.min = -this.elts.length*100 + 100
        this.Display = "100%"
        this.Opacity = 1
      }
    }
}
</script>
<style>
.visualizer {
  box-sizing: border-box;
  position: fixed;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
  overflow: hidden;
  transition: opacity 0.4s;
}
.visualContent {
    position:relative;
    width: 300%;
    height: 100%;
    display: flex;
    transition: left 1s;
}
.visualContent > div {
    width: 100%;
    height: 100%;
    display: flex;justify-content: center;align-items: center;
}
.visualContent > div > img {
    max-width: 100%;
}
.visualPoints {
  position:absolute;
  bottom: 0px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;justify-content: center;align-items: center;
}
.visualPoints > span {
  width: 7px;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: white;
  border-radius:3em;
}
.visualCross {
  position: absolute;
  right: 0px;
  top:0px;
  margin-right: 10px;
  margin-top: 10px;
  width: 28px;
  height: 28px;
  border-radius:7em;
  display: flex;justify-content: center;align-items: center;
  background-color: rgb(255, 255, 255,0.7);
  cursor: pointer;
}
.visualCross > img {
  width: 17px;
}
</style>