import { createWebHistory, createRouter } from 'vue-router'
import Landing from '@/components/landing/Landing'
import ObjectList from '@/components/objectList/ObjectList'
import Object from '@/components/object/Object'
import QrMenu from '@/components/qrMenu/QrMenu'
import authentication from '@/middleware/authentication.js'

const routes = [
  {
    path: '/qrMenu',
    name: 'qrMenu',
    component: QrMenu
  },
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      middleware: [authentication]
    }
  },
  {
    path: '/objectList/:search',
    name: 'objectList',
    component: ObjectList,
    meta: {
      middleware: [authentication]
    }
  },
  {
    path: '/object/:id',
    name: 'object',
    component: Object,
    meta: {
      middleware: [authentication]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
router