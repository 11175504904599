export default {
  name: 'lFooter',
  props: {
    display:  {
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      Display: this.display,
      text:`Voulez vous vraiment supprimer ?`
    }
  },
  emits:['clicked'],
  methods: {
    show(text) {
      this.text = text
      this.Display = true
    },
    clicked (message) {
      this.$emit('clicked',message)
      this.Display = false
    }
  }
}
