import JNavbar from '@/components/global/jNavbar/JNavbar'
import RCaroussel from '@/components/global/rCaroussel/RCaroussel'
import JFooter from '@/components/global/jFooter/JFooter'
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import RecoverAccount from '@/components/inscription/RecoverAccount'
import StepsForm from '@/components/global/stepsForm/StepsForm'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import LLoading from '@/components/global/lLoading/LLoading'
import Confirm from '@/components/global/confirm/Confirm'
import ChatBox from '@/components/global/chatBox/ChatBox'
import ImgUpload from '@/components/global/imgUpload/ImgUpload'

export default {
  name: 'landing',
  components: {
    JNavbar,
    RCaroussel,
    JFooter,
    Inscription,
    Connexion,
    RecoverAccount,
    StepsForm,
    LLoading1,
    Confirm,
    ChatBox,
    ImgUpload,
    LLoading
  },
  data () {
    return {
      connected:this.$store.state.user.connected,
      loading:false,
      eltsList:[],
      eltsClosed:[],
      founders:[],
      toDeleteIndex:0,
      toCloseIndex:0,
      bUrl:this.$store.state.baseUrl + 'images/',
      loadProfile:false
    }
  },
  methods: {
    toObjectList (type) {
      this.$store.commit('setObjListSearch',{type:type,search:0})
      this.$router.push({name:'objectList', params: {search:1}})
    },
    setImage (elt) {
      if(elt.img0 !='') return this.bUrl + elt.img0
      else if(elt.img1 !='') return this.bUrl + elt.img1
      else if(elt.img2 !='') return this.bUrl + elt.img2
      else return this.bUrl + elt.img3
      //(elt.img0 !='' ? bUrl + elt.img0 : ( elt.img1 !='' ? bUrl + elt.img1 : (elt.img2 !='' ? bUrl + elt.img2 : bUrl+elt.img3 )))
    },
    changeProfile (img) {
        this.loadProfile = true

        let formData = new FormData()
        formData.append('image', img.file)
        formData.append('userId', this.$store.state.user.id)
        formData.append('type', 'saveImg')
        const axios = require('axios')
        axios.post(this.$store.state.baseUrl + 'inscription.php', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then(({data}) => {
          this.setCookie('userImg',data,5)
          this.$store.commit('saveUserImg',data)
          this.loadProfile = false
        })
        .catch((error) => {
          console.log(error)
          this.loadProfile = false  
        })
    },
    getObjects () {
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'publication.php', {
        aim:'get',
        id:this.$store.state.user.id
      })
        .then(({data}) => {
          this.loading = false
          this.eltsList = data.elts
          this.eltsClosed = data.closed
          this.$store.commit('setUserObject',{eltActive:data.elts, eltClosed:data.closed})
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    closing (index) {
      this.toCloseIndex = index
      this.$refs.confirm1.show('Cet objet n\'est plus disponible ?')
    },
    deleting (index) {
      this.toDeleteIndex = index
      this.$refs.confirm.show('Etes vous sure de vouloir supprimer ?')
    },
    deleteMe (index,verdict) {
      if(verdict == 'no') return
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'publication.php', {
        aim:'delete',
        id:this.eltsList[index].id,
        user: this.$store.state.user.id
      })
        .then(() => {
          this.eltsList.splice(index,1)
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    closeMe (index,verdict) {
      if(verdict == 'no') return
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'publication.php', {
        aim:'close',
        id:this.eltsList[index].id,
        user: this.$store.state.user.id
      })
        .then(() => {
          this.eltsClosed.push(this.eltsList[index])
          this.eltsList.splice(index,1)
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    edit (elt) {
      this.$refs.stepsFormEditL.loadObject(elt)
    },
    removeList(pl) {
      if(pl.type == 'found') {
        this.eltsClosed.push({...this.eltsFound[pl.index],name:pl.name,tel:pl.tel})
        this.eltsFound.splice(pl.index,1)
      }
      else {
        this.eltsClosed.push({...this.eltsLost[pl.index],name:pl.name,tel:pl.tel})
        this.eltsLost.splice(pl.index,1)
      }
    },
    //-- cookie useful functions ------------
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  },
  mounted () {
    this.$watch(
      () => this.$store.state.user.connected,
      (newVal) => {
        this.connected = newVal
        if(this.connected) this.getObjects()
      })

    if(this.$store.state.user.connected && !this.$store.state.userObject.created) this.getObjects()
    else {
      this.eltsClosed = this.$store.state.userObject.eltClosed
      this.eltsList = this.$store.state.userObject.eltActive
    }
  }
}
