export default {
  name: 'scrollTop',
  data () {
    return {
      position:'-100px'
    }
  },
  methods: {
    show () {
      this.position = '0px'
    },
    hide () {
      this.position = '-100px'
    }
  }
}
