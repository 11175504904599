<template>
    <div style="z-index:1" class ="lPublication" @click="toObject">
        <div class="lPImg" :style="{backgroundImage:'url('+(images.length > 0 ? images[0]:'')+')'}"></div>
        <div class="lPZone">
            <div class="lPText" > 
                <div class="lPDescription">{{ datas.description.slice(0,60) }}...</div>
                <div class="lPType">
                    <div>{{ datas.price }} FCFA</div>
                </div>
                <div class="lPWhere">{{ datas.type }}</div>
                <div>Disponible à {{ datas.city }} </div>
            </div>
        </div>
    </div>
</template>
<style src="./lPublication.css">
</style>
<script src="./lPublication.js">
</script>
