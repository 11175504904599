export default {
  name: 'contact',
  props: {
    imgUrl: {
      type:String,
      default:''
    },
    infos: {
      type: Object,
      default:{name:'',type:'',contact:''}
    }
  },
  data () {
    return {
      Display: false,
      copied: false
    }
  },
  methods: {
    cliked (event) {
      if(!this.$refs.contactMain.contains(event.target)) this.Display = false
    },
    displaying () {
      this.Display = true
    },
    copyTel () {
      navigator.clipboard.writeText(this.infos.contact)
      this.copied = true
    }
  }
}
