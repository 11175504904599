import InputI0 from '@/components/inscription/InputI0.vue'
import ButtonI0 from '@/components/inscription/ButtonI0.vue'
import Infos from '@/components/global/infos/Infos'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
export default {
  name: 'recoverAccount',
  components: {
    InputI0,
    ButtonI0,
    Infos,
    LLoading1,
  },
  data () {
    return {
      loading:false
    }
  },
  methods: {
    save () {
      if (!this.validation()) return
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'connexion.php', {
        type:'recoverAccount1',
        email: this.$refs.email.message,
        password: this.$refs.password.message
      })
        .then(({data}) => {
          this.loading = false
          if(data=='notExist') alert('Aucun compte associé à cet email n\'a été trouvé')
          else this.$refs.infos.displaying(this.$refs.email.message)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validateEmail (code) {
      this.loading = true
      const axios = require('axios')
      //axios.defaults.withCredentials = true
      axios.post(this.$store.state.baseUrl + 'connexion.php', {
        type:'recoverAccount2',
        code: code
      })
        .then(({data}) => {
          this.loading = false
          if(data === 'success') {
            this.$refs.alertG.displaying('Success')
            this.$router.push('/connexion')
          }
          else this.$refs.alertG.displaying('le code est incorrect')
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validation () {
      let regexEmail =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (!regexEmail.test(this.$refs.email.message)) {
        this.$refs.alertG.displaying('email incorrect')
        return false
      }
      if (this.$refs.password.message == '') {
        this.$refs.alertG.displaying('Mot de passe incorrect')
        return false
      }
      if (this.$refs.password.message !== this.$refs.confirmpw.message) {
        this.$refs.alertG.displaying('Les mots de passe ne correspondent pas.')
        return false
      }
      return true
    }
  }
}
