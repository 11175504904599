import ImageUploader from 'vue-image-upload-resize/src/components/ImageUploader'

export default {
  name: 'imgUpload',
  components: {
    ImageUploader
  },
  props: {
    id: {
      type: String,
      Default:"-1"
    },
    name:{
      type: String,
      default:'name'
    }
  },
  data () {
    return { 
      loading:false,
      file:null,
      url:'',
      modified: false
    }
  },
  emits:["imgInfos"],
  methods: {
    preview (file) {
      if (file) {
        this.file = file
        this.url = URL.createObjectURL(file)
        this.modified = true
        //---- emit-------------
        this.$emit('imgInfos',{url:this.url,file:this.file})
      }
    },
    clear ()  {
      this.url=''
      this.modified = true
    }
  }
}
