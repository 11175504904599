import { facebookDate } from '@/facebookDate'


export default {
  name: 'lPublication',
  props: {
    datas: {
      type: Object,
      default: {id:'-1',description:'pumom upsum ipsumum aruminum'},
    }
  },
  data () {
    return {
      images:[]
    }
  },
  methods: {
    fbDate (d) {return facebookDate(d)},
    toObject () {
      this.$store.commit('setCurrentObject',this.datas)
      this.$router.push({name:'object', params: {id: this.datas.id }})
    }
  },
  mounted () {
    let bUrl = this.$store.state.baseUrl + 'images/'
    if(this.datas.img0 != '' ) this.images.push(bUrl+this.datas.img0)
    if(this.datas.img1 != '' ) this.images.push(bUrl+this.datas.img1)
    if(this.datas.img2 != '' ) this.images.push(bUrl+this.datas.img2)
    if(this.datas.img3 != '' ) this.images.push(bUrl+this.datas.img3)
  }
}
