// @param - timeStamp - Javascript Date object or date string
// @usage - timeSince(new Date().setFullYear(2019))
export function facebookDate (timeStamp) {
  if (!(timeStamp instanceof Date)) {
    timeStamp = new Date(timeStamp)
  }

  if (isNaN(timeStamp.getDate())) {
    return 'Invalid date'
  }

  let now = new Date()
  let secondsPast = (now.getTime() - timeStamp.getTime()) / 1000

  
  if (secondsPast < 0) { // Future date
    return timeStamp
  }
  if (secondsPast < 60) { // Less than a minute
    return 'il y\'a '+ parseInt(secondsPast) + ' secs'
  }
  if (secondsPast < 3600) { // Less than an hour
    return 'il y\'a ' + parseInt(secondsPast / 60) + ' mins'
  }
  if (secondsPast <= 86400) { // Less than a day
    return 'il y\'a '+parseInt(secondsPast / 3600) + ' hrs'
  }
  if (secondsPast <= 604800) { // Less than a week
    return 'il y\'a '+parseInt(secondsPast / 86400) + ' jours'
  }
  if (secondsPast <= 2419200) { // Less than 4 weeks
    return 'il y\'a '+parseInt(secondsPast / 604800) + ' semaines'
  }
  if (secondsPast <= 31104000) { // Less 12 month
    return 'il y\'a '+parseInt(secondsPast / 2592000) + ' mois'
  }
  if (secondsPast >= 31104000) { // more than a year
    return 'il y\'a '+parseInt(secondsPast / 31104000) + ' ans'
  }

}
