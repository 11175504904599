<template>
    <div class="qrMenu">
        <div class="qrMenuBody">
            <h2>Modifier votre menu (pdf) </h2>
            <input placeholder="pdf" ref="file" type="file" accept="application/pdf">
            <div @click="save">Enregistrer</div>
            <a :href="this.$store.state.baseUrl+'menus/menu.pdf'" @click="toMenu" >Aperçu</a>
        </div>
    <JFooter style="display:none"/>
    <LLoading1 :visible="loading"/>
    </div>
</template>
<style src="./qrMenu.css">
</style>
<script src = "./qrMenu.js">
</script>
